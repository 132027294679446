import KeenSlider from "keen-slider";

class CMAMinuteWith extends HTMLElement {
	$keenslider;
	$slider;
	$inCaseYouMissedCnt;
	$prevBtn;
	$nextBtn;

	constructor() {
		super();

		this.$keenSlider = this.querySelector('.keen-slider');
		if (this.$keenSlider) {
			this.init();
		}

	}

	init = () => {
		this.$slider = new KeenSlider(this.$keenSlider, {
			breakpoints: {
				'(max-width: 430px)': {
					slides: {
						perView: 1,
						spacing: 16,
					}
				},
				'(min-width: 600px)': {
					slides: {
						perView: 1.2,
						spacing: 16,
					}
				},
				'(min-width: 1010px)': {
					slides: {
						perView: 2.25,
						spacing: 16,
					}
				}
			},
			loop: false,
			slideChanged: (s) => {
				const currentIndex = s.track.details.rel
				this.$prevBtn && this.$prevBtn.classList.toggle('disabled', currentIndex === 0);
				this.$nextBtn && this.$nextBtn.classList.toggle('disabled', currentIndex === s.track.details.maxIdx);
			}
		})

		this.$inCaseYouMissedCnt = this.querySelector('.aMinuteWithNav');
		this.$prevBtn = this.$inCaseYouMissedCnt.querySelector('[data-type="prev"]');
		this.$nextBtn = this.$inCaseYouMissedCnt.querySelector('[data-type="next"]');
		this.disableButton(this.$prevBtn);

		this.$inCaseYouMissedCnt.onclick = e => {
			const currentSlide = this.$slider?.track.details.rel
			const maxSlide = this.$slider?.track.details.maxIdx

			e && e.preventDefault();
			const $btn = e.target.closest('button');
			if (!$btn)
				return;

			const type = $btn.getAttribute('data-type');
			if (type == 'prev') {
				this.$slider.prev();
				this.removeDisabledButton(this.$nextBtn)
				if (currentSlide === 0) {
					this.disableButton($btn);
				}
			}

			if (type == 'next') {
				this.$slider.next();
				this.removeDisabledButton(this.$prevBtn);
				if (currentSlide === maxSlide) {
					this.disableButton($btn);
				}
			}
		}
	}

	disableButton = (buttonElement) => {
		if (!buttonElement) return;
		buttonElement.classList.add('disabled');
	}
	removeDisabledButton = (buttonElement) => {
		if (!buttonElement) return;
		buttonElement.classList.remove('disabled');
	}

	disconnectedCallback() {
		if (this.slider)
			this.slider.destroy();

	}
}

if (!customElements.get("cm-a-minute-with-slider"))
	customElements.define("cm-a-minute-with-slider", CMAMinuteWith);
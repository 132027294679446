import KeenSlider from "keen-slider"

class Tpl10 extends HTMLElement {

	$keenSlider;
	$lnkArticles;
	slider;
	$nav;

	constructor() {
        super();
		
		this.$keenSlider = this.querySelector('.keen-slider');
		
		if (this.$keenSlider) {
			this.init();
		}
	}

	onArticleClicked = (e) => {
		const $A = e.target.closest('.lnkArticle');

		if (!$A)
			return;

		const _idx = $A.getAttribute('data-idx');
		this.slider?.moveToIdx(Number(_idx));
	}

	init = () => {

		this.$lnkArticles = this.querySelectorAll('.allArticles > .lnkArticle')
		this.$articlesCnt = this.querySelector('.allArticles');

		this.slider = new KeenSlider(
			this.$keenSlider, 
			{
				loop: true,
				slides: {
					perView: 1
				},
				defaultAnimation: {
					duration: 1700,
				},
				detailsChanged: (s) => {
					s.slides.forEach((element, idx) => {
						const _p = s.track.details.slides[idx].portion;
						element.style.opacity = _p;
						element.style.zIndex = _p == 1 ? "2" : "1";
					})
				},
				renderMode: "custom",
				created: (slider) => {
					this.$lnkArticles[0] && this.$lnkArticles[0].classList.add('active');
				},
				slideChanged: (slider) => {
					const _idx = slider.track.details.rel;
					this.$lnkArticles.forEach( el => el.classList.remove('active'));
					this.$lnkArticles[_idx] && this.$lnkArticles[_idx].classList.add('active');
				}
				
			}
		)

		this.$articlesCnt?.addEventListener('click', this.onArticleClicked)

		this.$nav = this.querySelector(".navArrows")



		this.$nav.onclick = e => {
		
	
			e && e.preventDefault();
			const $btn = e.target.closest('button');
			if (!$btn)
				return;
	
			const type = $btn.getAttribute('data-type');
			if (type == 'prev') {
				this.slider.prev();
			
			}
	
			if (type == 'next') {
				this.slider.next();
	
			}
		}
	}

	disconnectedCallback() {
       	if (this.slider)
			this.slider.destroy();		

		this.$articlesCnt?.removeEventListener('click', this.onArticleClicked)
    }
}

if (!customElements.get("tpl-10"))
	customElements.define("tpl-10", Tpl10);


class HomeVideos extends HTMLElement {

	$videoStage;
	$articlesCnt;

	constructor() {
        super();
		
		this.$videoStage = this.querySelector('.cmVideosStage');
		this.$articlesCnt = this.querySelector('.cmVideosSelection');

		if (this.$articlesCnt) {
			this.$articlesCnt.addEventListener('click', this.onClickVideoThumb);
		}

		if (this.$videoStage) {
			this.$videoStage.addEventListener('click', this.onClickCenterStage)
		}
	}

	onClickCenterStage = (e) => {
		e.preventDefault();
		const $A = e.target.closest('.overlayedCentered');
		if (!$A)
			return;

		//this is the init first article
		this.$videoStage.innerHTML = this.createEmbedCode(window.arHomeVideos[0].videoSrc);

	}

	createEmbedCode = (videoSrc) => {
		let $el = document.createElement('div');
		$el.innerHTML = videoSrc;

		const $iframe = $el.querySelector("iframe");
		const _src = $iframe.getAttribute("src");
		const _url = new URL(_src);
		_url.searchParams.set('autoplay','1');

		$iframe.src = _url.toString();
		return $el.innerHTML;
	}

	onClickVideoThumb = (e) => {
		const $A = e.target.closest('button');
		
		if (!$A)
			return;

		const _id = $A.getAttribute('data-id');
		
		if (!window?.arHomeVideos)
			return;

		//try to find video
		const _V = window.arHomeVideos.find( V => V.id == _id);
		if (!_V)
			return;

		//set autoplay
		this.$videoStage.innerHTML = this.createEmbedCode(_V.videoSrc);
	}

	disconnectedCallback() {
		if (this.$videoStage) {
			this.$videoStage.innerHTML = '';
			this.$videoStage.removeEventListener('click', this.onClickCenterStage)
		}

		this.$articlesCnt?.removeEventListener('click', this.onClickVideoThumb);
 	}
}

if (!customElements.get("cm-home-videos"))
	customElements.define("cm-home-videos", HomeVideos);
import KeenSlider from "keen-slider"

class SpecialReports extends HTMLElement {

	$keenSlider;
	$lnkArticles;
	slider;

	constructor() {
        super();
		
		this.$keenSlider = this.querySelector('.keen-slider');
		
		if (this.$keenSlider) {
			this.init();
		}
	}

	onArticleClicked = (e) => {
		e.preventDefault();
		const $A = e.target.closest('a');
		
		if (!$A)
			return;

		if($A.getAttribute('href') !== "#"){
			window.location.href = $A.href;
		}
		
		const _idx = $A.getAttribute('data-idx');
		this.slider?.moveToIdx(Number(_idx));
	}

	init = () => {

		this.$lnkArticles = this.querySelectorAll('.allReports > .lnkArticle')
		this.$articlesCnt = this.querySelector('.allReports');

		this.slider = new KeenSlider(
			this.$keenSlider, 
			{
				loop: true,
				slides: {
					perView: 1
				},
				defaultAnimation: {
					duration: 1700,
				},
				detailsChanged: (s) => {
					s.slides.forEach((element, idx) => {
						const _p = s.track.details.slides[idx].portion;
						element.style.opacity = _p;
						element.style.zIndex = _p == 1 ? "2" : "1";
					})
				},
				renderMode: "custom",
				created: (slider) => {
					
				},
				slideChanged: (slider) => {
					const _idx = slider.track.details.rel;
					this.$lnkArticles.forEach( el => el.classList.remove('active'));
					this.$lnkArticles[_idx] && this.$lnkArticles[_idx].classList.add('active');
				}
			}
		)

		this.$articlesCnt?.addEventListener('click', this.onArticleClicked)
	}

	disconnectedCallback() {
       	if (this.slider)
			this.slider.destroy();		

		this.$articlesCnt?.removeEventListener('click', this.onArticleClicked)
    }
}

if (!customElements.get("special-reports"))
	customElements.define("special-reports", SpecialReports);
import KeenSlider from "keen-slider"

class CMTopSlider extends HTMLElement {

	$keenSlider;
	$lnkArticles;
	$articlesCnt;
	slider;
	$inCaseYouMissedCnt;

	constructor() {
        super();
		
		this.$keenSlider = this.querySelector('.keen-slider');
		
		if (this.$keenSlider) {
			this.init();
		}
	}


	onTopSliderArticleClicked = (e) => {
		// e.preventDefault();
		const $A = e.target.closest('article');

		if (!$A)
			return;

		const _idx = $A.getAttribute('data-idx');
		this.slider?.moveToIdx(Number(_idx));
	}

	init = () => {

		this.$lnkArticles = this.querySelectorAll('.cmTopSliderArticles > div > article')
		this.$articlesCnt = this.querySelector('.cmTopSliderArticles');

		this.slider = new KeenSlider(
			this.$keenSlider, 
			{
				loop: true,
				slides: {
					perView: 1,
					spacing: 16
				},
				created: (slider) => {
					
				},
				slideChanged: (slider) => {
					const _idx = slider.track.details.rel;
					this.$lnkArticles.forEach( el => el.classList.remove('active'));
					this.$lnkArticles[_idx] && this.$lnkArticles[_idx].classList.add('active');
				}
			}
		)

		this.$articlesCnt?.addEventListener('click', this.onTopSliderArticleClicked)

		this.$inCaseYouMissedCnt = this.querySelector(".navArrows")



		this.$inCaseYouMissedCnt.onclick = e => {
		
	
			e && e.preventDefault();
			const $btn = e.target.closest('button');
			if (!$btn)
				return;
	
			const type = $btn.getAttribute('data-type');
			if (type == 'prev') {
				this.slider.prev();
			
			}
	
			if (type == 'next') {
				this.slider.next();
	
			}
		}
	}

	disconnectedCallback() {
       	if (this.slider)
			this.slider.destroy();

		this.$articlesCnt?.removeEventListener('click', this.onTopSliderArticleClicked)
		
    }
}

if (!customElements.get("cm-top-slider"))
	customElements.define("cm-top-slider", CMTopSlider);
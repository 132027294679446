class Tpl20 extends HTMLElement {
   slug = "";
   $nextButton;
   $prevButton;
   $getNewPage;
   $getPrevPage;

   constructor() {
      super();
      this.slug = document.querySelector("tpl-20").getAttribute("data-type");
      this.$nextButton = document.getElementById("nextButton");
      this.$prevButton = document.getElementById("prevButton");
      this.$getNextPage = () => {
         this.getNewArticles(1);
      };
      this.$getPrevPage = () => {
         this.getNewArticles(-1);
      };
      this.$nextButton.addEventListener("click", this.$getNextPage);
      this.$prevButton.addEventListener("click", this.$getPrevPage);
   }

   toggleNextButton(articlesCount) {
      if (articlesCount < 10) {
         document.getElementById("nextButton").style.display = "none";
      } else {
         document.getElementById("nextButton").style.display = "flex";
      }
   }
   togglePrevButton(offset) {
      if (offset < 2) {
         document.getElementById("prevButton").style.display = "none";
      } else {
         document.getElementById("prevButton").style.display = "flex";
      }
   }
   disabledButtons() {
      document.getElementById("nextButton").disabled = true;
      document.getElementById("prevButton").disabled = true;
   }
   enableButtons() {
      document.getElementById("nextButton").disabled = false;
      document.getElementById("prevButton").disabled = false;
   }

   getNewArticles(step) {
      this.disabledButtons();
      let offset = document
         .getElementById("bulkArticles")
         .getAttribute("data-type");

      offset = parseInt(offset) + step;
      document.getElementById("bulkArticles").setAttribute("data-type", offset);
      const articlesSection = document.getElementById("bulkArticles");
      fetch(`/breaking-news-section?slug=${this.slug}&offset=${offset}`)
         .then((res) => {
            res.text().then((text) => {
               articlesSection.innerHTML = text;
               const articlesCount = text?.match(/<article\b/g).length || 0;
               this.toggleNextButton(articlesCount);
            });
         })
         .finally(() => {
            this.enableButtons();
         });
      this.togglePrevButton(offset);
   }

   disconnectedCallback() {
      this.$nextButton.removeEventListener("click", this.$getNextPage);
      this.$prevButton.removeEventListener("click", this.$getPrevPage);
   }
}

if (!customElements.get("tpl-20")) customElements.define("tpl-20", Tpl20);
